/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/indigo-pink.css';

body {
    background: #f2f2f7;;
}

html, body {
    font-family: 'Noto Sans', sans-serif;
    color: #18171C;
}